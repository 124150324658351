import React from 'react';
import SEO from '../components/seo/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function FreightState () { 

  const images = useStaticQuery(graphql`
    query {
      bkg: file(relativePath: {eq: "freight-state/bkg--freight-state-text.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      frg: file(relativePath: {eq: "freight-state/frg--freight-state-1.jpg"}) {
        childImageSharp {
          fixed(width: 1346, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `);

  return (
    <>
      <SEO title='Freight State' />
      <Img  fixed={ images.bkg.childImageSharp.fixed } alt='Freight State' />
      <Img style={ { position: 'absolute', left: '574px' } } fixed={ images.frg.childImageSharp.fixed } alt='Environment' />
    </>
  );

}
